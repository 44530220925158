<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on" @click="getGuestFaculty">
          border_color
        </v-icon>
        <v-icon color="primary" dark v-on="on" v-else large>
          add
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="!id"
                >Add guest faculty experience</span
              >
              <span class="text-h5 font-weight-bold mx-3" v-else
                >Edit guest faculty experience</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
            </v-col>
            <v-col cols="1">
              <v-icon
              color="green"
              @click="createGuestFacultyExperince"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateGuestFacultyExperince" dark large dense>
              done
            </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="guestFacultyExpForm">
          <v-card-text>
            <v-container>
              <!-- <v-row>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                  Designation Name <span class="red--text">*</span>
                </div>
                <v-text-field
                    label=""
                    outlined
                    v-model="guestFacultyExp.designationName"
                    required
                  ></v-text-field>
                </v-col>
                
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Institute Name <span class="red--text">*</span>
                </div>
                  <v-text-field
                    label=""
                    outlined
                    v-model="guestFacultyExp.instituteName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Number of sessions delivered <span class="red--text">*</span>
                </div>
                  <v-select
                    outlined
                    :items="[
                      '<20',
                      '250+',
                      '750+',
                      '1000+',
                      '20+',
                      '50+',
                      '70+',
                      '100+',
                      '200+'
                    ]"
                    v-model="guestFacultyExp.numberOfSessionDelivered"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="12">
                   <div class="text-h6 font-weight-bold mx-2">
                  Key skills <span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                    v-model="guestFacultyExp.keySkills"
                    class="elevation-0"
                    label=""
                    chips
                    multiple
                    clearable
                    placeholder="After typing press enter to input more values"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="select"
                        close
                        @click:close="removeKeySkills(data.item)"
                        color="secondary"
                        text-color="black"
                        small
                      >
                        <strong>{{ data.item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                   <div class="text-h6 font-weight-bold mx-2">
                  Description <span class="red--text"></span>
                </div>
                  <v-textarea
                    outlined
                    v-model="guestFacultyExp.description"
                    name="input-7-4"
                    label=""
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Start date <span class="red--text"></span></div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-model="guestFacultyExp.startDate"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      type="month"
                      @input="menu = false"
                      v-model="guestFacultyExp.startDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  End date <span class="red--text"></span></div>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-model="guestFacultyExp.endDate"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="menu1 = false"
                      type="month"
                      v-model="guestFacultyExp.endDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6"> </v-col>
              </v-row>
            </v-container>
            <small class="text-color-red">*indicates required field</small>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GuestFacultyDailog",
  data() {
    return {
      dialog: false,
      menu: false,
      select: ["add-tags-with", "enter", "tab", "paste"],
      valid: false,
      menu1: false,
      rules: {
        isRequired: [(v) => !!v || "Required"]
      },
      startYear: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    };
  },
  props: ["id"],
  methods: {
    removeKeySkills(item) {
      console.log("key skill to be removed", item);
      this.guestFacultyExp.keySkills.splice(
        this.guestFacultyExp.keySkills.indexOf(item),
        1
      );
      this.guestFacultyExp.keySkills = [...this.guestFacultyExp.keySkills];
    },
    closeForm() {
      this.dialog = false;
      this.$refs.guestFacultyExpForm.reset();
    },
    cb(){
      this.dialog = false;
      this.$refs.guestFacultyExpForm.reset();
    },
    createGuestFacultyExperince() {
      if(this.$refs.guestFacultyExpForm.validate()){
        this.guestFacultyExp.trainerId =this.profile.trainerId
      this.$store.dispatch('trainerProfile/createGuestFaculty',{guestFaculty:this.guestFacultyExp,cb:this.cb})
      }
      
    },
    getGuestFaculty(){
        this.$store.dispatch('trainerProfile/getGuestFaculty',this.id)
    },
    updateGuestFacultyExperince() {
      console.log("update guest faculty exp---", this.guestFacultyExp);
      if(this.$refs.guestFacultyExpForm.validate()){
      this.$store.dispatch('trainerProfile/updateGuestFaclty',{guestFaculty:this.guestFacultyExp,cb:this.cb})
      }
    }
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      guestFacultyExp: "guestFacultyExp"
    })
  }
};
</script>
<style></style>
