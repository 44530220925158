<template>
  <v-container>
    <div v-if="guestFauculyList.length > 0">
      <v-list
        v-for="(guestFacultyEntity, index) in guestFauculyList"
        :key="index"
        color="#ECF0F3"
      >
        <v-row>
          <v-card class="mt-1 mb-4" flat outlined width="600px">
            <v-row justify="space-between">
              <v-col cols="6">
                <div class="text-h6 font-weight-bold mx-3">
                  <span class="primary--text">Guest Faculty</span>
                  @ {{ guestFacultyEntity.instituteName }}
                </div>
              </v-col>
              <v-col cols="3">
                <GuestFacultyDailog :id="guestFacultyEntity.id" />
              </v-col>
            </v-row>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <div class="text-h7 font-weight-bold primary--text my-n2">
                    Start date
                  </div>
                </v-col>
                <v-col cols="6">
                 <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ guestFacultyEntity.startDate }}
                </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 font-weight-bold primary--text my-n2">
                    End date
                  </div>
                </v-col>
                <v-col cols="6">
                 <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ guestFacultyEntity.endDate}}
                </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 primary--text  font-weight-bold my-n2">
                    Number of sessions delivered
                  </div>
                </v-col>
                <v-col cols="6">
                 <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ guestFacultyEntity.numberOfSessionDelivered}}
                </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 font-weight-bold primary--text my-n2">
                    Key skills
                  </div>
                </v-col>
                <v-col cols="6">
                    <div class="text-h7 text--primary font-weight-bold my-1" v-for="(skill,index) in guestFacultyEntity.keySkills" :key="index">
                  {{skill}}
                </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 font-weight-bold primary--text my-n2">
                    Description
                  </div>
                </v-col>
                <v-col cols="6">
                 <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ guestFacultyEntity.description}}
                </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-list>
    </div>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import GuestFacultyDailog from "./guestFacultyDailog.vue";
export default {
  name: "guestFacultyCard",
  components: {
    GuestFacultyDailog
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(
      "trainerProfile/getAllGuestFaculty",
      this.profile.trainerId
    );
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      guestFauculyList: "guestFauculyList"
    })
  }
};
</script>

<style></style>
