<template>
  <v-container>
    <div v-if="workExpList.length > 0">
      <v-list
        v-for="(workExEntity, index) in workExpList"
        :key="index"
        color="#ECF0F3"
      >
        <v-card class="mt-1 mb-4" flat outlined width="600px">
          <v-row justify="space-between">
            <v-col cols="6">
              <div class="text-h6 font-weight-bold mx-3">
                <span class="primary--text">{{
                  workExEntity.designation
                }}</span>
                @ {{ workExEntity.companyName }}
              </div>
            </v-col>
            <v-col cols="3"><WorkExDailog :id="workExEntity.id" /> </v-col>
          </v-row>
          <v-card-text>
            <v-row>
               <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold my-n2">
                  Ot mapped designation
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.otMappedDesignation }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold my-n2">
                  Start date
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.startDate }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold my-n2">
                  Is currently working here
                </div>
              </v-col>
              <v-col cols="6"> 
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{workExEntity.currentlyWorking?"Yes":"No"}}
                </div>
                
              </v-col>
              <v-col cols="6" v-if="!workExEntity.currentlyWorking">
                <div class="text-h7 primary--text font-weight-bold my-n2">
                  End date
                </div>
              </v-col>
              <v-col cols="6" v-if="!workExEntity.currentlyWorking">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.endDate }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text my-n2">
                  Department/Function
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.department }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text my-n2">
                  Nature of engagement
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.natureOfEngagement }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold my-n2">
                  Roles and responsibility
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 text--primary font-weight-bold my-n2">
                  {{ workExEntity.rolesAndResponsibilites }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold my-1">
                  Key skills
                </div>
              </v-col>
              <v-col cols="6">
                <div
                  class="text-h7 text--primary font-weight-bold my-1"
                  v-for="(skill, index) in workExEntity.keySkills"
                  :key="index"
                >
                  {{ skill }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 primary--text font-weight-bold">
                  Tools in roles and responsiblities
                </div>
              </v-col>
              <v-col cols="6">
                <div
                  class="text-h7 text--primary font-weight-bold"
                  v-for="(tools, index) in workExEntity.keyTools"
                  :key="index"
                >
                  {{ tools }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-list>
    </div>
    <!-- <v-card v-else>
    <h1>No work ex assocaited with trainer</h1>
  </v-card> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import WorkExDailog from "../components/WorkExpDialog/WorkExpDialog.vue";
export default {
  name: "WorkExpCard",
  data() {
    return {};
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      workExpList: "workExpList"
    })
  },
  components: {
    WorkExDailog
  },
  async created() {
    let workListResponse = await this.$store.dispatch(
      "trainerProfile/getAllWorkEx",
      this.profile.trainerId
    );
    console.log("workListResponse  ----", workListResponse);
  }
};
</script>

<style></style>
