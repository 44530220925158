<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col>
        <ProfileCard />
      </v-col>
      <v-col>
      
      </v-col>
      <v-col>
       
      </v-col>
    </v-row>
        <v-row><v-col cols="4"><Span class="text-h5 font-weight-bold mx-4">Work experience</span> </v-col>
        <v-col cols="2"> <WorkExpDialog/></v-col>
        </v-row>
    <v-row>
      <v-col cols="6">
        <WorkExpCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
          <span class="text-h5 font-weight-bold mx-4">Guest faculty experience</span>
        </v-col>
        <v-col cols="2">
          <GuestFacluty/>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <GuestFacultyCard/>
    </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"><span class="text-h5 font-weight-bold mx-4">Degree Education <ToolTip :tooltipText="workxHelpText.education"/></span>  </v-col>
      <v-col cols="2"><EducationDailoge/></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <EducationCard />
      </v-col>
    </v-row>
  <div :class="true?activeClass:nonActive">
             Admin comments
    </div>
   <AdminComments :showAddButton="true" type="WORK_EXPERINCE"/>
  </v-container>
  
</template>

<script>
import GuestFacluty from "@/components/guestFacultyDailog.vue"
import SecondaryNavigation from "@/components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "@/components/ProfileCard/ProfileCard.vue";
import AdminComments from "@/components/AdminComments/AdminComments.vue"
import WorkExpDialog from "@/components/WorkExpDialog/WorkExpDialog.vue"
import WorkExpCard from "@/components/WorkExpCard.vue"
import GuestFacultyCard from "@/components/guestFacultyCard.vue"
import EducationDailoge from "@/components/educationDailoge.vue"
import EducationCard from '../../../components/EducationCard.vue'
import {WorkExperince } from '../../../data/HelpTexts'
import ToolTip from '../../../components/Tooltip/tooltip.vue'
export default {
  name: "AdminConsole",
  components: {
    SecondaryNavigation,
    ProfileCard,
   AdminComments,
    WorkExpDialog,
    WorkExpCard,
    GuestFacluty,
    GuestFacultyCard,
    EducationDailoge,
    EducationCard ,
    ToolTip
  },
  data() {
    return {
      
       activeClass:"text-h6 font-weight-bold mx-3",
      nonActive:"text-h6 primary--text font-weight-bold",
      workxHelpText:WorkExperince 
          };
  },
  methods:{
    
  }
};
</script>

<style></style>
