<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on" @click="getWorkEx">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
      </template>
      <v-card >
        <v-card-title>
          <v-col>
            <span class="text-h5 font-weight-bold mx-3" v-if="!id"
              >Add work experience</span
            >
            <span class="text-h5 font-weight-bold mx-3" v-else
              >Edit work experience</span
            >
          </v-col>
          <v-col cols="1">
            <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col cols="1">
            <v-icon
              color="green"
              @click="createWorkEx"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateWorkEx" dark large dense>
              done
            </v-icon>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="workExFrom">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Designation <span class="red--text">*</span>
                </div>
                  <v-text-field
                    label=""
                    v-model="workEx.designation"
                    :rules="rules.isRequired"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                 OT mapped designation <span class="red--text">*</span>
                </div>
                  <v-combobox
                    label=""
                    :items="otMappedDesignation"
                    placeholder="Select or type ot mapped designation"
                    v-model="workEx.otMappedDesignation"
                    :rules="rules.isRequired"
                    required
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Organization Name <span class="red--text">*</span>
                </div>
                  <v-combobox
                   outlined  
                   placeholder="Type Organization name .."
                   :items="organizations"
                    v-model="workEx.companyName"
                    :rules="rules.isRequired"
                    label=""
                    hint=""
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Department<span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                    v-model="workEx.department"
                    :rules="rules.isRequired"
                    label=""
                    required
                    placeholder="Type department name"
                    :items="departments"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Industry<span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                  :items="insudtry"
                    v-model="workEx.industry"
                    :rules="rules.isRequired"
                    label=""
                    placeholder="Type industry name"
                    required
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <div class="text-h6 font-weight-bold mx-2">
                  Nature of Engagement<span class="red--text">*</span>
                </div>
                  <v-select
                    outlined
                    :items="natureOfEngageMent"
                    label=""
                    placeholder="Select nature of engagement"
                    v-model="workEx.natureOfEngagement"
                    :rules="rules.isRequired"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                  Roles and Responsibilities<span class="red--text"></span>
                </div>
                  <v-textarea
                  outlined
                    label=""
                    v-model="workEx.rolesAndResponsibilites"
                    :rules="rules.isRequired"
                    
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                   <div class="text-h6 font-weight-bold mx-2">
                  Key Skills<span class="red--text"></span>
                </div>
                  <v-combobox
                    outlined
                    class="elevation-0"
                    label=""
                    chips
                    multiple
                    clearable
                    v-model="workEx.keySkills"
                    placeholder="After typing press enter to input more values"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="select"
                        close
                        @click:close="removeKeySkills(data.item)"
                        color="secondary"
                        text-color="black"
                        small
                      >
                        <strong>{{ data.item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                  Tools in roles and responsiblities<span class="red--text"></span>
                </div>
                  <v-combobox
                  outlined
                    class="elevation-0"
                    label=""
                    chips
                    multiple
                    clearable
                    v-model="workEx.keyTools"
                    placeholder="After typing press enter to input more values"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="select"
                        close
                        @click:close="removeTools(data.item)"
                        color="secondary"
                        text-color="black"
                        small
                      >
                        <strong>{{ data.item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                <v-checkbox
              class="text-h6 font-weight-bold text--primary"
                v-model="workEx.currentlyWorking"
                label="Is currently working here ?"
              />
              </v-col>
                <v-col cols="12" sm="4" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Start Date<span class="red--text">*</span>
                </div>
                  <v-menu
                    
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="workEx.startDate"
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        placeholder="Select start date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="workEx.startDate"
                      type="month"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!workEx.currentlyWorking">
                  <div class="text-h6 font-weight-bold mx-2">
                  End Date<span class="red--text">*</span>
                </div>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="workEx.endDate"
                        label=""
                        placeholder="Select end date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    type="month"
                      v-model="workEx.endDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6"> </v-col>
              </v-row>
            </v-container>
            <small class="text-color-red">*indicates required field</small>
          </v-card-text>
        </v-form>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeForm" rounded
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="createWorkEx"
            rounded
            v-if="!id"
            >Save</v-btn
          >
          <v-btn color="blue darken-1" text @click="updateWorkEx" rounded v-else
            >Update work ex</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import {NatureOfEngagementWorkx,Departments,Industry,CleintList,TargetAuinceCourseOutline} from '@/data/FormData'
export default {
  data: () => ({
    dialog: false,
    menu: false,
    organizations:CleintList,
    otMappedDesignation:TargetAuinceCourseOutline,
    select: ["add-tags-with", "enter", "tab", "paste"],
    valid: false,
    menu1: false,
    departments:Departments,
    insudtry:Industry,
    rules: {
      isRequired: [(v) => !!v || "Required"]
    },
    natureOfEngageMent:NatureOfEngagementWorkx,
    startYear: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10)
  }),
  props: ["id"],
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      workEx: "workEx"
    })
  },
  methods: {
    cb() {
      this.dialog = false;
      this.$refs.workExFrom.reset();
    },
    closeForm() {
      this.dialog = false;
      this.$refs.workExFrom.reset();
    },
    createWorkEx() {
      if (this.$refs.workExFrom.validate()) {
        console.log("trainer id", this.profile.trainerId);
        console.log("new work ex-", this.workEx);
        let updatedWorkEx = this.workEx;
        updatedWorkEx.trainerId = this.profile.trainerId;
        //{ workEx, cb }
        console.log("updated updatedWorkEx  ---", updatedWorkEx);
        this.$store.dispatch("trainerProfile/createWorkEx", {
          workEx: updatedWorkEx,
          cb: this.cb
        });
      }
    },
    removeKeySkills(item) {
      console.log("list of tools   ----", item);
      this.workEx.keySkills.splice(this.workEx.keySkills.indexOf(item), 1);
      this.workEx.keySkills = [...this.workEx.keySkills];
    },
    removeTools(item) {
      let keyTools = Object.assign({}, this.workEx.keyTools);
      console.log("key tools ---", keyTools);
      this.workEx.keyTools.splice(this.workEx.keyTools.indexOf(item), 1);
      this.workEx.keyTools = [...this.workEx.keyTools];
    },
    getWorkEx() {
      console.log("get work ex called");
      this.$store.dispatch("trainerProfile/getAWorkEx", this.id);
    },
    updateWorkEx() {
      this.$store.dispatch("trainerProfile/updateWorkEx", {workx:this.workEx,cb:this.cb});
    }
  }
};
</script>
