<template>
  <v-container fluid>
      <v-row justify="center">
            <v-dialog v-model="dialog" max-width="1400px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on"  @click="getEducation">
          border_color
        </v-icon>
        <v-icon color="primary" dark v-on="on" v-else large>
          add
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="!id"
                >Add education detials</span
              >
              <span class="text-h5 font-weight-bold mx-3" v-else
                >Edit education detials</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
            </v-col>
            <v-col cols="1">
              <v-icon
              color="green"
              @click="createEducationDetials"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateEducationDetails" dark large dense>
              done
            </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="edcationForm">
          <v-card-text>
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                 Institute name <span class="red--text">*</span>
                </div>
                <v-text-field
                    label=""
                    outlined
                    placeholder="Enter institute name..."
                    v-model="education.college"
                    required
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Degree <span class="red--text">*</span>
                </div>
                  <v-text-field
                    label=""
                    outlined
                     placeholder="Enter degree name..."
                    v-model="education.degree"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Specialization <span class="red--text">*</span>
                </div>
                  <v-text-field
                    outlined
                     placeholder="Enter specialization type..."
                      v-model="education.specialization"
                    label=""
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                   <div class="text-h6 font-weight-bold mx-2">
                  Description <span class="red--text"></span>
                </div>
                  <v-textarea
                    outlined
                    placeholder="Tell breif description about your time spent there"
                    v-model="education.description"
                    name="input-7-4"
                    label=""
                    required
                  ></v-textarea>
                </v-col> -->
                <v-col cols="6" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Start date <span class="red--text"></span></div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label=""
                         placeholder="Select start date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-model="education.startDate"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="menu = false"
                      type="month"
                      v-model="education.startDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  End date <span class="red--text"></span></div>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        placeholder="Select end  date"
                        v-model="education.endDate"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="menu1 = false"
                      type="month"
                      v-model="education.endDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6"> </v-col>
              </v-row>
               <small class="text-h7 font-weight-bold red--text">*indicates required field</small>
            </v-container>
           
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
      </v-row>
      
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
name:"EducationDailoge",
components:{

},
data() {
    return {
        dialog: false,
      menu: false,
      select: ["add-tags-with", "enter", "tab", "paste"],
      valid: false,
      menu1: false,
      rules: {
        isRequired: [(v) => !!v || "Required"]
      },
      startYear: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    }
},
props: ["id"],
computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      education: "education"
    })
  },
methods:{
getEducation(){
  console.log('get education');
  this.$store.dispatch('trainerProfile/getEducation',this.id)   
},
closeForm(){
    this.dialog=false,
    this.$refs.edcationForm.reset()
},
cb(){
this.dialog=false,
this.$refs.edcationForm.reset()
},
updateEducationDetails(){
  this.$store.dispatch('trainerProfile/updateEducation',{education:this.education, cb:this.cb})
},
createEducationDetials(){
    if(this.$refs.edcationForm.validate()){
        this.education.trainerId=this.profile.trainerId
        console.log('educaiton ----' , this.education);
        this.$store.dispatch('trainerProfile/createEducation',{education:this.education, cb:this.cb})
    }
},
}

}
</script>

<style>

</style>