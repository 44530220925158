var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.id)?_c('v-icon',_vm._g({attrs:{"color":"primary","dark":""},on:{"click":_vm.getGuestFaculty}},on),[_vm._v(" border_color ")]):_c('v-icon',_vm._g({attrs:{"color":"primary","dark":"","large":""}},on),[_vm._v(" add ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[(!_vm.id)?_c('span',{staticClass:"text-h5 font-weight-bold mx-3"},[_vm._v("Add guest faculty experience")]):_c('span',{staticClass:"text-h5 font-weight-bold mx-3"},[_vm._v("Edit guest faculty experience")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"red","dark":"","large":"","dense":""},on:{"click":_vm.closeForm}},[_vm._v(" cancel ")])],1),_c('v-col',{attrs:{"cols":"1"}},[(!_vm.id)?_c('v-icon',{attrs:{"color":"green","dark":"","large":"","dense":""},on:{"click":_vm.createGuestFacultyExperince}},[_vm._v(" done ")]):_c('v-icon',{attrs:{"color":"blue","dark":"","large":"","dense":""},on:{"click":_vm.updateGuestFacultyExperince}},[_vm._v(" done ")])],1)],1)],1),_c('v-divider'),_c('v-form',{ref:"guestFacultyExpForm"},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" Institute Name "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-text-field',{attrs:{"label":"","outlined":"","required":""},model:{value:(_vm.guestFacultyExp.instituteName),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "instituteName", $$v)},expression:"guestFacultyExp.instituteName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" Number of sessions delivered "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-select',{attrs:{"outlined":"","items":[
                    '<20',
                    '250+',
                    '750+',
                    '1000+',
                    '20+',
                    '50+',
                    '70+',
                    '100+',
                    '200+'
                  ],"label":""},model:{value:(_vm.guestFacultyExp.numberOfSessionDelivered),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "numberOfSessionDelivered", $$v)},expression:"guestFacultyExp.numberOfSessionDelivered"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" Key skills "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-combobox',{staticClass:"elevation-0",attrs:{"outlined":"","label":"","chips":"","multiple":"","clearable":"","placeholder":"After typing press enter to input more values"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"input-value":_vm.select,"close":"","color":"secondary","text-color":"black","small":""},on:{"click:close":function($event){return _vm.removeKeySkills(data.item)}}},[_c('strong',[_vm._v(_vm._s(data.item))])])]}}]),model:{value:(_vm.guestFacultyExp.keySkills),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "keySkills", $$v)},expression:"guestFacultyExp.keySkills"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" Description "),_c('span',{staticClass:"red--text"})]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"","required":""},model:{value:(_vm.guestFacultyExp.description),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "description", $$v)},expression:"guestFacultyExp.description"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" Start date "),_c('span',{staticClass:"red--text"})]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.guestFacultyExp.startDate),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "startDate", $$v)},expression:"guestFacultyExp.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.guestFacultyExp.startDate),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "startDate", $$v)},expression:"guestFacultyExp.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h6 font-weight-bold mx-2"},[_vm._v(" End date "),_c('span',{staticClass:"red--text"})]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.guestFacultyExp.endDate),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "endDate", $$v)},expression:"guestFacultyExp.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.guestFacultyExp.endDate),callback:function ($$v) {_vm.$set(_vm.guestFacultyExp, "endDate", $$v)},expression:"guestFacultyExp.endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}})],1)],1),_c('small',{staticClass:"text-color-red"},[_vm._v("*indicates required field")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }