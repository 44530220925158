<template>
  <v-container>
    <div v-if="educationList.length > 0">
    <v-list v-for="(educationEntitiy, index) in educationList"
        :key="index"
        color="#ECF0F3">
      <v-row>
        <v-card  flat  width="600px">
          <v-row justify="space-between">
              <v-col cols="6">
                <div class="text-h6 font-weight-bold mx-3">
                  <span class="primary--text">{{
                    educationEntitiy.degree
                  }}</span>
                  @ {{ educationEntitiy.college }}
                </div>
              </v-col>
              <v-col cols="3">
                <EditEducationDailoge :id="educationEntitiy.id" />
              </v-col>
            </v-row>
          <v-card-text>
              <v-row>
                <v-col cols="6">
                  <div class="text-h7 primary--text font-weight-bold my-n9">Specialization</div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 text--primary font-weight-bold my-n9">{{educationEntitiy.specialization}}</div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 primary--text font-weight-bold my-n6">Start Date</div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 text--primary font-weight-bold my-n6">
                    {{educationEntitiy.startDate}}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 primary--text font-weight-bold my-n4">End Date</div>
                </v-col>
                <v-col cols="6">
                  <div class="text-h7 text--primary font-weight-bold my-n4">
                    {{educationEntitiy.endDate}}
                  </div>
                </v-col>
                <!-- <v-col cols="6">
                  <div class="text-h7 primary--text font-weight-bold my-n4">Description</div>
                </v-col> -->
                <!-- <v-col cols="6">
                  <div class="text-h7 text--primary font-weight-bold my-n4">
                    {{educationEntitiy.description}}
                  </div>
                </v-col> -->
              </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-list>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import EditEducationDailoge from "./educationDailoge.vue";
export default {
  name: "EducationCard",
  components: {
    EditEducationDailoge
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      educationList: "educationList"
    })
  },
   created() {
      this.$store.dispatch('trainerProfile/getAllEducationList', this.profile.trainerId)
  },
};
</script>

<style></style>
